import {Component, OnInit} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions} from 'ngx-gallery';
import {TopPageService} from '../top-page.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  public topOfPage = true;
  private _service: TopPageService;

  constructor(service: TopPageService) {
    this._service = service;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.galleryOptions = [
      {
        width: '100%',
        height: '100%',
        imagePercent: 80,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageSize: NgxGalleryImageSize.Contain,
        preview: false,
        image: true,
        imageBullets: true,
        imageInfinityMove: true,
        previewZoom: false,
        imageAutoPlay: true,
        thumbnails: false,
        imageAutoPlayInterval: 5000,
        imageArrows: false
      },
      {
        breakpoint: 800,
        width: '100%',
        height: '100%',
        imagePercent: 80,
        imageSize: NgxGalleryImageSize.Contain,
        preview: false,
        image: true,
        imageBullets: true,
        imageInfinityMove: true,
        previewZoom: false,
        imageAutoPlay: true,
        thumbnails: false,
        imageAutoPlayInterval: 5000,
        imageArrows: false
      },
      {
        breakpoint: 480,
        width: '100%',
        height: '100%',
        preview: false,
        imageSize: NgxGalleryImageSize.Contain,
        image: true,
        imageBullets: true,
        imageInfinityMove: true,
        previewZoom: false,
        imageAutoPlay: true,
        thumbnails: false,
        imageAutoPlayInterval: 5000,
        imageArrows: false
      }
    ];

    this.galleryImages = [
      {
        small: '../../assets/img/c1.png',
        medium:  '../../assets/img/c1.png',
        big:  '../../assets/img/c1.png',
      },
      {
        small: '../../assets/img/c2.png',
        medium:  '../../assets/img/c2.png',
        big:  '../../assets/img/c2.png',
      },
      {
        small: '../../assets/img/c3.png',
        medium:  '../../assets/img/c3.png',
        big:  '../../assets/img/c3.png',
      },
      {
        small: '../../assets/img/c4.png',
        medium:  '../../assets/img/c4.png',
        big:  '../../assets/img/c4.png',
      },
      {
        small: '../../assets/img/c5.png',
        medium:  '../../assets/img/c5.png',
        big:  '../../assets/img/c5.png',
      },
    ];
  }
}
