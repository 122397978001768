import {Component, OnInit} from '@angular/core';
import {TopPageService} from './top-page.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  private _service: TopPageService;

  constructor(service: TopPageService) {
    this._service = service;
  }

  ngOnInit(): void {
    window.onscroll = () => this.onScroll();
  }

  onScroll() {
    this._service.topOfPage.next(!(document.body.scrollTop > 95 || document.documentElement.scrollTop > 95));
  }
}
