import {Component, Input, OnInit} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryLayout, NgxGalleryOptions, NgxGalleryOrder} from 'ngx-gallery';

@Component({
  selector: 'app-cake-gallery',
  templateUrl: './cake-gallery.component.html',
  styleUrls: ['./cake-gallery.component.scss']
})
export class CakeGalleryComponent implements OnInit {

  @Input()
  public header: string;

  @Input()
  public className: string;

  @Input()
  public galleryPicturePrefix: string;

  @Input()
  public galleryRefCodePrefix: string;

  @Input()
  public lgRows: number;

  @Input()
  public lgCols: number;

  @Input()
  public mobRows: number;

  @Input()
  public numberOfPics: number;

  @Input()
  public galleryFolderName: string;

  @Input()
  public lgHeight: string;

  @Input()
  public mdHeight: string;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.galleryOptions = [
      {
        height: this.lgHeight ? this.lgHeight : '400vh',
        width: '100vw',
        imageAnimation: NgxGalleryAnimation.Slide,
        imageSize: NgxGalleryImageSize.Contain,
        layout: NgxGalleryLayout.ThumbnailsTop,
        thumbnailsColumns: this.lgCols,
        thumbnailsRows: this.lgRows,
        image: false,
        imageBullets: true,
        imageArrows: true,
        thumbnailMargin: 20,
        thumbnailsOrder: NgxGalleryOrder.Page,
      },
      {
        breakpoint: 1024,
        height: this.mdHeight ? this.mdHeight : '200vh',
        width: '100vw',
        preview: true,
        imageSize: NgxGalleryImageSize.Contain,
        layout: NgxGalleryLayout.ThumbnailsTop,
        image: false,
        thumbnailsColumns: 4,
        thumbnailsRows: this.mobRows,
        previewBullets: true,
        previewZoom: true,
        thumbnailMargin: 10,
        thumbnailsOrder: NgxGalleryOrder.Page,
      },
      {
        breakpoint: 480,
        height: '150vh',
        preview: true,
        imageSize: NgxGalleryImageSize.Contain,
        layout: NgxGalleryLayout.ThumbnailsTop,
        image: false,
        thumbnailsColumns: 4,
        thumbnailsRows: this.mobRows,
        previewBullets: true,
        previewZoom: true,
        thumbnailsOrder: NgxGalleryOrder.Page,
        thumbnailMargin: 2,
      }
    ];

    this.galleryImages = [];
    for (let i = 1; i < (this.numberOfPics + 1); i++) {
      this.galleryImages.push(
        {
          small: `../../assets/img/${this.galleryFolderName}${this.galleryPicturePrefix} (${i}).jpg`,
          medium:  `../../assets/img/${this.galleryFolderName}${this.galleryPicturePrefix} (${i}).jpg`,
          big:  `../../assets/img/${this.galleryFolderName}${this.galleryPicturePrefix} (${i}).jpg`,
          description: `Cake reference code: ${this.galleryRefCodePrefix}${i}`
        }
      );
    }
  }
}
