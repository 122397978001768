import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HomePageComponent} from './home-page/home-page.component';
import {LocationAndHoursComponent} from './location-and-hours/location-and-hours.component';
import {WelcomePageComponent} from './welcome-page/welcome-page.component';
import {ServicesOfferedSummaryComponent} from './services-offered-summary/services-offered-summary.component';
import {NavBarComponent} from './nav-bar/nav-bar.component';
import {ServicesOfferedCardComponent} from './services-offered-summary/services-offered-card/services-offered-card.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {SectionOneComponent} from './section-one/section-one.component';
import {SectionTwoComponent} from './section-two/section-two.component';
import {SectionThreeComponent} from './section-three/section-three.component';
import {ParallaxModule} from 'ngx-parallax';
import {RouterModule, Routes} from '@angular/router';
import {WeddingCakeGalleryComponent} from './wedding-cake-gallery/wedding-cake-gallery.component';
import {NgxGalleryModule} from 'ngx-gallery';
import {CakeGalleryComponent} from './cake-gallery/cake-gallery.component';
import {BirthdayCakeMensGalleryComponent} from './birthday-cake-mens-gallery/birthday-cake-mens-gallery.component';
import {BirthdayCakeWomensGalleryComponent} from './birthday-cake-womens-gallery/birthday-cake-womens-gallery.component';
import {BirthdayCakeGirlsGalleryComponent} from './birthday-cake-girls-gallery/birthday-cake-girls-gallery.component';
import {BirthdayCakeBoysGalleryComponent} from './birthday-cake-boys-gallery/birthday-cake-boys-gallery.component';
import {CupcakesGalleryComponent} from './cupcakes-gallery/cupcakes-gallery.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {FaqComponent} from './faq/faq.component';
import {ContactusComponent} from './contactus/contactus.component';
import {HttpClientModule} from '@angular/common/http';
import {TopPageService} from './top-page.service';
import {VeganComponent} from './vegan/vegan.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {WeddingCakeSmallGalleryComponent} from './wedding-cake-small-gallery/wedding-cake-small-gallery.component';

const appRoutes: Routes = [
  { path: '', data: { breadcrumb: 'Home'}, children: [
      { path: '', component: HomePageComponent },
      { path: 'aboutus', data: { breadcrumb: 'About Us' }, component: AboutUsComponent },
      { path: 'contactus', data: { breadcrumb: 'Contact Us' }, component: ContactusComponent },
      { path: 'vegan', data: { breadcrumb: 'Vegan' }, component: VeganComponent },
      { path: 'privacypolicy', data: { breadcrumb: 'Privacy' }, component: PrivacyPolicyComponent },
      { path: 'faq', data: { breadcrumb: 'FAQ' }, component: FaqComponent },
      { path: '', children: [
          { path: 'weddings', children: [
              { path: 'weddings', data: { breadcrumb: 'Weddings: Weddings ' }, component: WeddingCakeGalleryComponent },
              { path: 'small', data: { breadcrumb: 'Weddings: Small' }, component: WeddingCakeSmallGalleryComponent },
            ]
          },
          { path: 'birthdays', children: [
              { path: 'mens', data: { breadcrumb: 'Birthdays: Mens ' }, component: BirthdayCakeMensGalleryComponent },
              { path: 'womens', data: { breadcrumb: 'Birthdays: Womens' }, component: BirthdayCakeWomensGalleryComponent },
              { path: 'girls', data: { breadcrumb: 'Birthdays: Girls' }, component: BirthdayCakeGirlsGalleryComponent },
              { path: 'boys', data: { breadcrumb: 'Birthdays: Boys' }, component: BirthdayCakeBoysGalleryComponent },
            ]
          },
          { path: 'cupcakes', data: { breadcrumb: 'Cupcakes' }, component: CupcakesGalleryComponent },
        ],
      },
    ]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    LocationAndHoursComponent,
    WelcomePageComponent,
    ServicesOfferedSummaryComponent,
    ServicesOfferedCardComponent,
    NavBarComponent,
    AboutUsComponent,
    SectionOneComponent,
    SectionTwoComponent,
    SectionThreeComponent,
    CakeGalleryComponent,
    WeddingCakeGalleryComponent,
    WeddingCakeSmallGalleryComponent,
    BirthdayCakeMensGalleryComponent,
    BirthdayCakeWomensGalleryComponent,
    BirthdayCakeGirlsGalleryComponent,
    BirthdayCakeBoysGalleryComponent,
    CupcakesGalleryComponent,
    BreadcrumbComponent,
    FaqComponent,
    ContactusComponent,
    VeganComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {}
    ),
    BrowserModule,
    ParallaxModule,
    NgxGalleryModule,
    HttpClientModule
  ],
  providers: [TopPageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
