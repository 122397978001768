import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  public _name = '';
  public _email = '';
  public _desc = '';
  public _phone = '';
  public _eventDate = '';

  constructor() {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  submitToAPI(e) {
    e.preventDefault();
    const URL = 'https://rrgzu2yzd1.execute-api.eu-west-2.amazonaws.com/contactus';

    const Namere = /[A-Za-z]{1}[A-Za-z]/;
    if (!Namere.test($('#name-input').val())) {
      alert ('Name must be longer than 2 characters.');
      return;
    }
    const mobilere = /[0-9]{10}/;
    if (!mobilere.test($('#phone-input').val())) {
      alert ('Please enter valid mobile number.');
      return;
    }
    if ($('#email-input').val() === '') {
      alert ('Please enter your email id.');
      return;
    }

    const reeamil = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
    if (!reeamil.test($('#email-input').val())) {
      alert ('Please enter valid email address.');
      return;
    }

    const data = {
      name : this._name,
      phone : this._phone,
      email : this._email,
      desc : this._desc,
      eventDate : this._eventDate
    };

    $.ajax({
      type: 'POST',
      url : 'https://rrgzu2yzd1.execute-api.eu-west-2.amazonaws.com/contactus/contactus',
      dataType: 'json',
      crossDomain: 'true',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),

      success: function () {
        // clear form and show a success message
        alert('Your enquiry has been sent, we will be in contact as soon as possible.');
        const a = (document.getElementById('contact-form')) as HTMLFormElement;
        a.reset();
        location.reload();
      },
      error: function () {
        // show an error message
        alert('Your enquiry was not sent');
      }});
  }

  addName(value: string) {
    this._name = value;
  }

  addPhone(value: string) {
    this._phone = value;
  }

  addEmail(value: string) {
    this._email = value;
  }

  addDesc(value: string) {
    this._desc = value;
  }

  addEventDate(value: string) {
    this._eventDate = value;
  }
}
