import { Component, OnInit } from '@angular/core';
import {TopPageService} from '../top-page.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  public topOfPage = true;
  public toggleMenu = false;
  public toggleBirthdayMenu = false;
  public toggleWeddingMenu = false;
  private _service: TopPageService;

  constructor(service: TopPageService) {
    this._service = service;
  }

  ngOnInit(): void {
    this._service.topOfPage.subscribe(x => { this.topOfPage = x; });
  }

  onMenuToggle() {
    this.toggleMenu = !this.toggleMenu;
  }

  onMenuRetract() {
    this.toggleMenu = false;
  }

  onBirthdayMenuToggle() {
    this.toggleBirthdayMenu = !this.toggleBirthdayMenu;
  }

  onBirthdayMenuRetract() {
    this.toggleBirthdayMenu = false;
  }

  onWeddingMenuToggle() {
    this.toggleWeddingMenu = !this.toggleWeddingMenu;
  }

  onWeddingMenuRetract() {
    this.toggleWeddingMenu = false;
  }
}
