import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-location-and-hours',
  templateUrl: './location-and-hours.component.html',
  styleUrls: ['./location-and-hours.component.scss']
})
export class LocationAndHoursComponent implements OnInit {
  public mobileView = false;
  public screenWidth: number;

  constructor() {
    this.getScreenSize();
  }

  ngOnInit() {
    if (window.innerWidth <= 480) {
      this.mobileView = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth  <= 480) {
      this.mobileView = true;
    }
  }
}
