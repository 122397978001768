import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-services-offered-card',
  templateUrl: './services-offered-card.component.html',
  styleUrls: ['./services-offered-card.component.scss']
})
export class ServicesOfferedCardComponent {

  @Input()
  public serviceName: string;

  @Input()
  public url: string;

  @Input()
  public className: string;

  @Input()
  public summary: string;

  public flipCard  = false;

  constructor() { }

  flip() {
    this.flipCard = !this.flipCard;
  }
}
